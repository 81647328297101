import en from "javascript-time-ago/locale/en";
import TimeAgo from "javascript-time-ago";

TimeAgo.addLocale(en);

export function timePassed(startTime: string, curated: boolean = false) {
  if (!startTime || startTime.length === 0) {
    return "";
  }

  const date = new Date(parseInt(startTime) * 1000);

  if (curated) {
    // if time difference is less than 24 hours
    if (new Date().getTime() - date.getTime() < 43200000 * 2) {
      return "today";
    }
  }
  const timeAgo = new TimeAgo("en-US");

  return timeAgo.format(date, "round-minute");
}

export function getTimeFromTimestamp(timestamp: string) {
  // convert from UTC "2023-08-16T22:05:26.384283" to local timezome
  const date = new Date(timestamp).toLocaleString();
  const [datePart, timePart] = date.split(", ");
  const [hour, minutes] = timePart.split(":");
  // const ampm = parseInt(hour) >= 12 ? "pm" : "am";
  const daysAgo = Math.floor(
    (new Date().getTime() - new Date(timestamp).getTime()) / 86400000
  );

  return daysAgo < 1 ? `${hour}:${minutes}` : `${daysAgo} days ago`;
}

export function getDate() {
  const today = new Date();
  const formattedDate = today.toLocaleDateString("en-US", {
    weekday: "long",
    month: "long",
    day: "numeric",
  });

  return formattedDate;
}
