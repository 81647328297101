import { atom } from "jotai";
import { InboxProps, UserStateProps } from "./types";

export const userState = atom({
  id: "",
  name: "",
  email: "",
  image_url: "",
  jwt: "",
  inbox: null,
} as UserStateProps);

export const getInboxState = atom((get) => get(userState).inbox);

export const updateInboxState = atom(
  (get) => get(userState).inbox?.id,
  (get, set, inbox: InboxProps | null) => {
    set(userState, { ...get(userState), inbox });
  }
);
