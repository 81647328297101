import { Box, Flex } from "@chakra-ui/react";
import Sidebar from "../../components/Sidebar";
import InboxList from "../../components/Messages/InboxList";
import MessagesView from "../../components/Messages/MessagesView";

const MessagesPage = () => {
  return (
    <Flex>
      <Sidebar />
      <InboxList />
      <MessagesView />
    </Flex>
  );
};

export default MessagesPage;
