import { useAuth0 } from "@auth0/auth0-react";
import {
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  Text,
  HStack,
  Icon,
  Image,
  Avatar,
} from "@chakra-ui/react";

import { RiLogoutBoxRLine } from "react-icons/ri";

const ProfileButton = () => {
  const { logout } = useAuth0();

  return (
    <Flex alignItems={"center"} my="auto">
      <Menu>
        <MenuButton>
          <Avatar
            name="Dan Abrahmov"
            src="assets/wh-logo.png"
            cursor="pointer"
          />
        </MenuButton>
        <MenuList borderRadius={6}>
          <HStack
            as={MenuItem}
            p={"8px 16px"}
            color="red.500"
            onClick={() => {
              logout();
            }}
          >
            <Icon as={RiLogoutBoxRLine} />
            <Text fontSize="sm" fontWeight={600}>
              Log out
            </Text>
          </HStack>
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default ProfileButton;
