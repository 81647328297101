// import { mode } from "@chakra-ui/theme-tools";

export const globalStyles = {
  colors: {
    kaya: {
      lightGray: "#FAFAFA",
      lightGray2: "#F4F4F4",
      lightGray3: "#D9D9D9",
      gray: "#797979",
      lightGreen: "#00AC44",
      green: "#005433",
      forest: "#233F39",
    },
  },
  // fonts inspired from https://www.fontpair.co/all
  fonts: {
    heading: `Montserrat, sans-serif`,
    body: `Montserrat, sans-serif`,
    // body: `Inter, sans-serif`,
  },
  fontSizes: {
    xs: "0.75rem",
    sm: "0.875rem",
    md: "0.9rem",
    lg: "1.125rem",
    xl: "1.25rem",
    "2xl": "1.5rem",
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "5xl": "3rem",
    "6xl": "4rem",
  },
  styles: {
    global: {
      html: {
        bg: "kaya.lightGray",
      },
      body: {
        height: "100vh",
        color: "kaya.navy",
        borderColor: "transparent",
      },
    },
  },
};
