import {
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  Modal,
  Text,
  Box,
  HStack,
  Input,
  Spacer,
  Center,
  Heading,
  Icon,
  Button,
  useToast,
  Image,
  VStack,
} from "@chakra-ui/react";

import {
  RiPhoneFill,
  RiSlackFill,
  RiTeamFill,
  RiUser3Line,
} from "react-icons/ri";

import Contacts from "./Contacts";
import { userState } from "../../store";
import { useAtom } from "jotai";
import { useWorkspaceDetails } from "../../hooks";
import { WORKSPACE_ID } from "../../constants";

const SettingsModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const [user, setUser] = useAtom(userState);

  const { data: workspaceDetails } = useWorkspaceDetails(WORKSPACE_ID);

  const windowWidth = "800px";

  return (
    <Modal onClose={onClose} isOpen={isOpen} closeOnOverlayClick={true}>
      <ModalOverlay />
      <ModalContent borderRadius={6} p={6} minW={windowWidth}>
        <HStack>
          {/* <Icon as={RiSettings3Line} boxSize={6} /> */}
          <Heading size="md">Workspace Settings</Heading>
        </HStack>
        <ModalCloseButton top={4} right={4} />

        <VStack align="left" spacing={4} mt={6}>
          <HStack>
            <Icon as={RiPhoneFill} boxSize={6} color="kaya.green" />
            <Text fontWeight={"600"}>Phone Number</Text>
          </HStack>
          <Input
            w="50%"
            disabled
            bg="kaya.lightGray"
            value={workspaceDetails?.phone_number}
            borderRadius={6}
          />
        </VStack>

        <VStack align="left" spacing={4} mt={6}>
          <HStack>
            <Icon as={RiSlackFill} boxSize={6} color="kaya.green" />
            <Text fontWeight={"600"}>Slack Channel</Text>
          </HStack>
          {/* disable button */}
          <Button
            w="50%"
            variant="gray"
            isDisabled
            _hover={{ cursor: "not-allowed" }}
            borderRadius={6}
          >
            #{workspaceDetails?.slack_channel}
          </Button>
        </VStack>

        <VStack spacing={6} align="left" mt={6}>
          <HStack>
            <Icon as={RiTeamFill} boxSize={6} color="kaya.green" />
            <Text fontWeight={"600"}>Contacts</Text>
          </HStack>

          <Contacts />

          <HStack w="full">
            <Spacer />
            <Button w="80px" variant="green" onClick={onClose}>
              Save
            </Button>
          </HStack>
        </VStack>
      </ModalContent>
    </Modal>
  );
};

export default SettingsModal;
