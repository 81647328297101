import { Text, Heading, VStack, HStack, Spacer } from "@chakra-ui/react";

import _ from "lodash";

import InboxItem from "./InboxItem";
import { InboxProps } from "../../types";

import { useInboxes } from "../../hooks";
import { useAtom } from "jotai";
import { userState } from "../../store";

const InboxList = () => {
  const { data: inboxes, isLoading } = useInboxes();
  const [user] = useAtom(userState);

  if (isLoading) return <></>;

  return (
    <VStack
      align="left"
      h="100vh"
      display={{ base: user.inbox ? "none" : "block", md: "flex" }}
      w="400px"
      minW="400px"
      overflowY={"scroll"}
      // sx={SCROLLBAR_CSS}
      sx={{ "&::-webkit-scrollbar": { display: "none" } }}
      borderRightWidth={1}
      borderColor={"kaya.lightGray3"}
      bg="kaya.lightGray2"
      spacing={0}
    >
      <VStack align="left">
        <HStack spacing={6} p={6}>
          <Heading fontSize="2xl" fontWeight={800} color="kaya.green">
            Messages
          </Heading>
          <Spacer />
        </HStack>
      </VStack>
      <VStack
        align="left"
        overflowY={"scroll"}
        sx={{ "&::-webkit-scrollbar": { display: "none" } }}
        spacing={0}
      >
        {inboxes
          ?.sort(
            (a: InboxProps, b: InboxProps) =>
              new Date(b.last_message.timestamp).getTime() -
              new Date(a.last_message.timestamp).getTime()
          )
          .map((inbox: InboxProps, i: number) => {
            return <InboxItem key={i} inbox={inbox} />;
          })}
      </VStack>
    </VStack>
  );
};

export default InboxList;
