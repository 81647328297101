import axios from "axios";
import { UserStateProps } from "../types";
import { WORKSPACE_PHONE_NUMBER } from "../constants";

const API_URI =
  process.env.REACT_APP_ENV === "production"
    ? process.env.REACT_APP_API_PROD
    : process.env.REACT_APP_API_DEV;

export const getUser = async (
  userId: string
  // jwt: string
) => {
  // const params = new URLSearchParams([["email", email]]);
  const { data } = await axios.get(`${API_URI}/user/user/${userId}`, {
    headers: {
      "Content-Type": "application/json",
      // Authorization: `Bearer ${jwt}`,
    },
  });
  return data;
};

// TODO: pass workspace phone number
export const createUser = async (name: string, email: string) => {
  const [first_name, last_name] = name.split(" ");
  const { data } = await axios.post(
    `${API_URI}/user/create_user/`,
    {
      first_name: first_name,
      last_name: last_name,
      email: email,
      phone_number: "",
      workspace_phone_number: WORKSPACE_PHONE_NUMBER,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return data;
};
