import axios from "axios";
import { InboxMessagesProps, InboxProps, WorkspaceProps } from "../types";

const API_URI =
  process.env.REACT_APP_ENV === "production"
    ? process.env.REACT_APP_API_PROD
    : process.env.REACT_APP_API_DEV;

export const getInboxes = async (): Promise<InboxProps[]> => {
  const params = new URLSearchParams([["limit", "100"]]);
  const { data } = await axios.get(`${API_URI}/inbox/inbox/`, {
    params,
    headers: {
      "Content-Type": "application/json",
    },
  });
  return data;
};

export const getInboxMessages = async (
  inboxId: string
): Promise<InboxMessagesProps> => {
  const { data } = await axios.get(
    `${API_URI}/inbox/inbox_messages/${inboxId}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};

export const createMessage = async (
  contractorId: string,
  userId: string,
  content: string
): Promise<void> => {
  const params = {
    inbox_user_id: contractorId,
    user_id: userId,
    content,
    source: "client",
  };

  await axios.post(`${API_URI}/inbox/create_inbox_message/`, params, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getWorkspaceDetails = async (
  workspaceId: string
): Promise<WorkspaceProps> => {
  const { data } = await axios.get(
    `${API_URI}/workspace/workspace/${workspaceId}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};
