import { Text, HStack, VStack, Spacer, Icon } from "@chakra-ui/react";
import { getTimeFromTimestamp } from "../../utils";
import { BiCheckDouble } from "react-icons/bi";

import { InboxProps } from "../../types";
import { getInboxState, updateInboxState } from "../../store";
import { useAtom } from "jotai";

const InboxItem = ({ inbox }: { inbox: InboxProps }) => {
  const [_inbox] = useAtom(getInboxState);
  const [, setInbox] = useAtom(updateInboxState);

  return (
    <HStack
      // borderTopWidth={1}
      // borderBottomWidth={1}
      bg={_inbox?.id === inbox.id ? "kaya.lightGray3" : ""}
      borderColor={"kaya.lightGray3"}
      p={6}
      _hover={{
        // bg: "kaya.lightGray3",
        cursor: "pointer",
      }}
      onClick={() => setInbox(inbox)}
    >
      <HStack w="full">
        <VStack align="start" spacing={1}>
          <Text fontWeight="bold" fontSize="sm">
            {inbox.contractor.name} ({inbox.contractor.phone_number})
          </Text>
          <Text fontSize="sm">{inbox.last_message.content}</Text>
        </VStack>
        <Spacer />
        <VStack align="end" spacing={0}>
          <Text fontSize="sm">
            {getTimeFromTimestamp(inbox.last_message.timestamp)}
          </Text>
          {/* <Icon as={BiCheckDouble} boxSize={6} /> */}
        </VStack>
      </HStack>
    </HStack>
  );
};

export default InboxItem;
