import { useState } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Input,
  Icon,
} from "@chakra-ui/react";
import uuid from "react-uuid";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { useWorkspaceDetails } from "../../hooks";

// const initialEntries = [
//   {
//     id: uuid(),
//     name: "Alexander Mount",
//     phone: "+1234567890",
//     projects: "41 Madison Ave",
//   },
//   {
//     id: uuid(),
//     name: "Emily Johnson",
//     phone: "+5558887777",
//     projects: "123 Oak Street",
//   },
// ];

const Contacts = () => {
  // const [entries, setEntries] = useState(initialEntries);
  const WH_ID = "cf969b06-5eb8-4f92-9c84-963866b9e923";
  const { data: workspaceDetails } = useWorkspaceDetails(WH_ID);

  const [newEntry, setNewEntry] = useState({
    name: "",
    phone: "",
    projects: "",
  });

  // TODO: handle new entries and write to db

  // const handleInputChange = (e: any) => {
  //   setNewEntry((prevEntry) => ({
  //     ...prevEntry,
  //     [e.target.name]: e.target.value,
  //   }));
  // };

  // const handleAddEntry = () => {
  //   setEntries((prevEntries) => [...prevEntries, { id: uuid(), ...newEntry }]);
  //   setNewEntry({ name: "", phone: "", projects: "" });
  // };

  // const handleDeleteEntry = (id: any) => {
  //   setEntries((prevEntries) => prevEntries.filter((entry) => entry.id !== id));
  // };

  return (
    <Box>
      <Table>
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Phone</Th>
            <Th>Projects</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {workspaceDetails?.user_list.map((workspaceDetail) => (
            <Tr key={workspaceDetail.user.id}>
              <Td fontSize="sm">{workspaceDetail.user.name}</Td>
              <Td fontSize="sm">{workspaceDetail.user.phone_number}</Td>
              <Td fontSize="sm">{workspaceDetail.project.name}</Td>
              {/* <Td>
                <Icon
                  cursor="pointer"
                  color="red.500"
                  as={RiDeleteBin5Fill}
                  onClick={() => handleDeleteEntry(entry.id)}
                />
              </Td> */}
            </Tr>
          ))}
        </Tbody>
      </Table>
      {/* <Button mt={6} variant="gray" size="sm">
        New Contact
      </Button> */}
    </Box>
  );
};

export default Contacts;
