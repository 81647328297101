export const componentStyles = {
  components: {
    //
    Heading: {
      baseStyle: {
        color: "kaya.green",
        fontWeight: "800",
        // letterSpacing: ".5px",
      },
    },
    //
    Text: {
      baseStyle: {
        fontSize: "md",
      },
    },
    //
    Tooltip: {
      baseStyle: {
        color: "white",
        bg: "#000132E6",
        borderRadius: 6,
        fontSize: "sm",
        fontWeight: 500,
        padding: 2,
      },
    },
    //
    Skeleton: {
      baseStyle: {
        w: "full",
        speed: 1,
        borderRadius: 6,
      },
    },
    //
    Menu: {
      baseStyle: {
        item: {
          _focus: {
            bg: "kaya.lightGray",
          },
        },
        list: {
          boxShadow: "md",
        },
      },
    },
    //
    Button: {
      baseStyle: {
        // borderRadius: "full",
        // fontWeight: "500",
        _focus: {
          boxShadow: "none !important",
        },
      },
      variants: {
        gray: {
          bg: "kaya.lightGray2",
          _hover: {
            bg: "kaya.lightGray2",
          },
        },
        white: {
          bg: "white",
          _active: {
            bg: "kaya.lightGray",
          },
          _hover: {
            bg: "kaya.lightGray",
          },
        },
        green: {
          bg: "kaya.green",
          color: "white",
          // _active: {
          //   bg: "kaya.lightGreen",
          // },
          // _hover: {
          //   bg: "kaya.lightGreen",
          // },
        },
      },
    },
  },
};
