import { Box, HStack, Spacer, Text, VStack } from "@chakra-ui/react";

import Inputbar from "../Input/Inputbar";

import { MessageProps } from "../../types";
import MessagesBubble from "./MessagesBubble";

import { CloseIcon } from "@chakra-ui/icons";
import { useAtom } from "jotai";
import { useInboxMessages } from "../../hooks";
import { getInboxState, updateInboxState } from "../../store";

const MessagesView = () => {
  const [inbox] = useAtom(getInboxState);
  const [, setIndex] = useAtom(updateInboxState);
  const { data: messages, isLoading } = useInboxMessages(inbox?.id!);

  if (!inbox || isLoading) return <></>;

  return (
    <VStack
      w={{ base: "100%", lg: "calc(100% - 500px)" }}
      minW="400px"
      h={"100vh"}
      align="left"
      spacing={0}
      mx="auto"
    >
      {/* Top */}
      <HStack h={"80px"} bg="kaya.forest" p={6} fontWeight="bold" color="white">
        <Text fontWeight="bold" fontSize="sm">
          {messages?.contractor.name}
        </Text>

        <Text fontWeight="bold" fontSize="sm">
          ({messages?.contractor.phone_number})
        </Text>

        <Spacer />
        <CloseIcon
          color="white"
          cursor="pointer"
          onClick={() => setIndex(null)}
        />
      </HStack>

      {/* Messages */}
      <VStack w="full" h="full" p={6} spacing={4} overflowY={"scroll"}>
        {messages?.messages.map((message: MessageProps, i: number) => (
          <MessagesBubble key={i} message={message} />
        ))}
      </VStack>

      {/* Bottom */}
      <Box w="full" bg="kaya.lightGray2">
        <Inputbar borderRadius={10} placeholder={"Send a message..."} />
      </Box>
    </VStack>
  );
};

export default MessagesView;
