import { Text, Box, BoxProps, HStack, Icon, VStack } from "@chakra-ui/react";

import { FC, memo, useEffect, useRef, useState } from "react";

import TextareaAuto from "./TextareaAuto";
import { createMessage } from "../../api/messages";
import { userState } from "../../store";
import { useAtom } from "jotai";
import { useCreateMessage, useInboxes } from "../../hooks";
import { RiSendPlane2Fill } from "react-icons/ri";
import { GrAttachment } from "react-icons/gr";

interface InputBarProps extends BoxProps {
  placeholder?: string;
  disabled?: boolean;
}

const Inputbar: FC<InputBarProps> = memo((props) => {
  const [user, setUser] = useAtom(userState);
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null);
  const createMessage = useCreateMessage();
  const { data: inboxes } = useInboxes();

  const handleChange = (e: any) => {
    setInputValue(e.target.value);
  };

  // focus input on ctrl+enter
  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (event.key === "Enter" && (event.ctrlKey || event.metaKey)) {
        event.preventDefault();
        // @ts-ignore
        inputRef.current?.focus();
      }
    };
    // @ts-ignore
    inputRef.current?.focus();
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  function handleMessage() {
    if (inputValue.trim() === "") return;

    createMessage.mutate({
      contractorId: user.inbox?.contractor.id!,
      userId: user.id,
      content: inputValue,
    });

    setInputValue("");
  }

  return (
    <HStack p={"16px 32px"} justify={"center"} spacing={8} align="flex-end">
      <VStack w="full" spacing={0}>
        <HStack
          w="100%"
          bg="white"
          // p="10px 22px"
          borderRadius={"full"}
          borderColor="transparent"
          align="flex-end"
          {...props}
        >
          {/* Input area */}
          <TextareaAuto
            ref={inputRef}
            p="12px 32px"
            borderColor={"transparent"}
            _hover={{
              borderColor: "transparent",
            }}
            _focusVisible={{
              borderColor: "transparent",
            }}
            placeholder={props.placeholder}
            _placeholder={{ opacity: 1, color: "kaya.gray" }}
            value={inputValue}
            onChange={handleChange}
            fontSize="md"
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                handleMessage();
              }
            }}
          />
        </HStack>
      </VStack>
      <Icon
        as={RiSendPlane2Fill}
        boxSize="36px"
        pb="8px"
        onClick={handleMessage}
        cursor="pointer"
        color={inputValue.length ? "kaya.green" : "kaya.lightGray3"}
      />
    </HStack>
  );
});

export default Inputbar;
