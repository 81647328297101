export const MAX_W = "1000px";

export const SHADOW = "2px 6px 16px 0px #282c3426";

export const SCROLLBAR_CSS = {
  "&::-webkit-scrollbar": {
    width: "0.4em",
  },
  "&::-webkit-scrollbar-track": {
    boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0,0,0,.1)",
    borderRadius: "20px",
  },
};

export const MOBILE = window.innerWidth < 768;

export const WORKSPACE_ID = "cf969b06-5eb8-4f92-9c84-963866b9e923";
export const WORKSPACE_PHONE_NUMBER = "+18444370020";
