import {
  Image,
  Text,
  Box,
  Icon,
  VStack,
  Spacer,
  Avatar,
  useDisclosure,
} from "@chakra-ui/react";

import ProfileMenu from "./Settings/ProfileButton";
import { RiSettings3Line } from "react-icons/ri";
import SettingsModal from "./Settings/SettingsModal";
import { useAtom } from "jotai";
import { userState } from "../store";

const Sidebar = () => {
  const {
    isOpen: settingsIsOpen,
    onOpen: settingsOnOpen,
    onClose: settingsOnClose,
  } = useDisclosure();

  const [user] = useAtom(userState);

  return (
    <Box h={"full"} display={{ base: user.inbox ? "none" : "block", md: "flex" }}>
      {/* sidebar buttons */}
      <VStack
        maxW="100px"
        p={6}
        // pb={"40px"}
        h={"100vh"}
        spacing={10}
        bg="white"
        borderColor={"kaya.lightGray3"}
        borderRightWidth={"1px"}
      >
        <Image src="assets/kaya-k.png" boxSize={8} />
        <Spacer />
        <VStack
          spacing={0}
          color="kaya.gray"
          _hover={{ cursor: "pointer", color: "kaya.green" }}
          onClick={settingsOnOpen}
        >
          <Icon as={RiSettings3Line} boxSize="32px" />
          <Text fontSize="xs" fontWeight={600}>
            Settings
          </Text>
        </VStack>
        <ProfileMenu />
        <SettingsModal isOpen={settingsIsOpen} onClose={settingsOnClose} />
      </VStack>
    </Box>
  );
};

export default Sidebar;
