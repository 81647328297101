import ResizeTextarea from "react-textarea-autosize";
import React, { memo } from "react";
import { Textarea, TextareaProps } from "@chakra-ui/react";

const TextareaAuto = memo(
  React.forwardRef((props: TextareaProps, ref) => {
    return (
      <Textarea
        // @ts-ignore
        ref={ref}
        as={ResizeTextarea}
        autoFocus
        minH="unset"
        overflow="hidden"
        w="100%"
        resize="none"
        minRows={1}
        fontSize="sm"
        // borderWidth={0}

        {...props}
      />
    );
  })
);

export default TextareaAuto;
