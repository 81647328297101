import { useMutation, useQuery } from "react-query";
import {
  createMessage,
  getInboxMessages,
  getInboxes,
  getWorkspaceDetails,
} from "./api/messages";
import { queryClient } from "./components/Providers";

export const useInboxes = () => {
  return useQuery(["inboxes"], () => getInboxes(), {
    // refetchInterval: 5000,
  });
};

export const useInboxMessages = (id: string) => {
  return useQuery(["inboxMessages", id], () => getInboxMessages(id), {
    enabled: id !== undefined && id.length > 0,
    // refetchInterval: 5000,
  });
};

export const useCreateMessage = () => {
  return useMutation(
    ({
      contractorId,
      userId,
      content,
    }: {
      contractorId: string;
      userId: string;
      content: string;
    }) => {
      return createMessage(contractorId, userId, content);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("inboxMessages");
        queryClient.invalidateQueries("inboxes");
      },
    }
  );
};

export const useWorkspaceDetails = (id: string) => {
  return useQuery(["workspaceDetails", id], () => getWorkspaceDetails(id), {
    enabled: id !== undefined && id.length > 0,
    // refetchInterval: 5000,
  });
};
