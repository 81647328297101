import { useAuth0 } from "@auth0/auth0-react";
import { Box } from "@chakra-ui/react";

import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams,
} from "react-router-dom";

import "../src/theme/fonts.css";

import { createUser } from "./api/users";
import MessagesPage from "./pages/Messages";
import { userState } from "./store";
import { useAtom } from "jotai";
import { useEffect } from "react";

const App = () => {
  const {
    getAccessTokenSilently,
    loginWithRedirect,
    isLoading,
    isAuthenticated,
    user: userAuth0,
  } = useAuth0();

  const [user, setUser] = useAtom(userState);
  const navigate = useNavigate();

  // auth user
  useEffect(() => {
    async function authUser() {
      if (!isLoading && !isAuthenticated) {
        loginWithRedirect();
      }
    }

    async function loadUser() {
      if (userAuth0 && !user?.jwt) {
        await getAccessTokenSilently().then((jwt) => {
          createUser(userAuth0.name!, userAuth0.email!).then((res) => {
            console.log(res);
            setUser({
              ...res,
              name: userAuth0.name!,
              jwt: jwt,
            });
          });
        });
      }
    }

    authUser();
    loadUser();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    navigate,
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    userAuth0,
    loginWithRedirect,
  ]);

  // console.log(user);

  if (!isAuthenticated) return null;

  return (
    <Box>
      <Routes>
        <Route path="/" element={<MessagesPage />} />
        {/* <Route path="c/:chatId" element={<MessagesPage />} /> */}

        <Route path="*" element={<Navigate to={"/"} replace />} />
      </Routes>
    </Box>
  );
};

export default App;
