import { Box, Text, HStack, Icon, Spacer, VStack } from "@chakra-ui/react";

import { MessageProps } from "../../types";
import _ from "lodash";
import { getTimeFromTimestamp } from "../../utils";
import { userState } from "../../store";
import { useAtom } from "jotai";

// handle source
// const formatSource = (source: string) => {
//   return _.startCase(_.toLower(source));
// };

const Time = ({ timestamp }: { timestamp: string }) => {
  return (
    <Text fontSize="sm" color="kaya.gray">
      {getTimeFromTimestamp(timestamp)}
    </Text>
  );
};

const MessagesBubble = ({ message }: { message: MessageProps }) => {
  const [user] = useAtom(userState);
  const me = message.user.full_name === user.name;
  // const greenText = message.source === "twilio";

  return (
    <HStack w="full">
      {me && <Spacer />}
      {me && <Time timestamp={message.timestamp} />}

      <VStack
        maxW="400px"
        spacing={1}
        align={"left"}
        p="12px 20px"
        borderRadius={me ? "12px 12px 0px 12px" : "0px 12px 12px 12px"}
        bg={me ? "kaya.green" : "kaya.lightGray2"}
        color={me ? "white" : "black"}
      >
        {!me && (
          <Text
            fontWeight={"bold"}
            display="flex"
            alignItems={"center"}
            // color={greenText ? "kaya.green" : ""}
          >
            {message.user.full_name}
            {/* {!greenText && (
              <Text pl={2} fontSize="sm" fontWeight={500} color="kaya.gray">
                ({message.user.phone_number})
              </Text>
            )} */}
          </Text>
        )}
        <Text>{message.content}</Text>
      </VStack>

      {!me && <Time timestamp={message.timestamp} />}
      {!me && <Spacer />}
    </HStack>
  );
};

export default MessagesBubble;
