import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { Provider as JotaiProvider } from "jotai";

import { ChakraProvider } from "@chakra-ui/react";
import Theme from "../theme";

export const queryClient = new QueryClient();

const Providers = ({ children }: any) => (
  <QueryClientProvider client={queryClient}>
    <ChakraProvider theme={Theme}>
      <JotaiProvider>
        <Auth0Provider
          domain={process.env.REACT_APP_AUTH0_DOMAIN}
          clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
          authorizationParams={{
            // NOTE: redirecting with react-router breaks auth0
            redirect_uri: window.location.origin,
            // audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          }}
          onRedirectCallback={(appState) => {
            window.history.replaceState(
              {},
              document.title,
              appState?.returnTo || window.location.pathname
            );
          }}
          cacheLocation="localstorage"
          // useRefreshTokens={true}
        >
          <BrowserRouter>{children}</BrowserRouter>
        </Auth0Provider>
      </JotaiProvider>
    </ChakraProvider>
  </QueryClientProvider>
);

export default Providers;
